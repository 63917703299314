import React from 'react'
import ReactDOM from 'react-dom'
// import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'


import App from './App'

// ReactDOM.render(<App />, document.getElementById('root'))

// createRoot(document.getElementById('root')).render(<Router><App /></Router>)

ReactDOM.render(
    <Router>
        <App />
    </Router>
    , document.getElementById('root'))