import React from 'react'
// import Card from '../card/Card'
import './Main.css'
import DynamicBlogCard from '../DynamicBlogCard'

const Main = ({blogs}) => {
  return (
    
    <section class="main">
      <div class="container">
        <div class="boxes">
        {blogs.map((blog, index) => (
        <DynamicBlogCard key={index} blog={blog} />
      ))}
          
        </div>
        </div>
      </section>
  )
}

export default Main