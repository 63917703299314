import React, { useEffect, useState } from 'react';
import { Navbar, Main, Footer } from '../Components';
import Loading from '../Components/loading/Loading';

const Home = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch('https://raw.githubusercontent.com/abhijeets00/writingcell-blog-data/main/blog-data.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, []);

  return (
    <div>
      <Navbar />
      {loading ? <Loading /> : <Main blogs={blogs} />}
      <Footer />
    </div>
  );
};

export default Home;
