import React from 'react';
import Card from './card/Card';

const DynamicBlogCard = ({ blog }) => {
  return (
    <Card
      title={blog.title}
      date={blog.date}
      coverUrl={blog.coverUrl}
      category={blog.category}
      textContent={blog.textcontent}
      page={`/${blog.title.replace(/\s/g, '')}`}
    />
  );
}

export default DynamicBlogCard;
