import React from 'react';
import './Blog.css';
import bgtn from "../../Assets/bgtn.png";
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

const Blog = ({ blog }) => {
  const { title, date, category, coverUrl, textcontent } = blog;

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="area_ba">
          <div className="blogarea">
            <h1>{title}</h1>
            <ul>
              <li>{date}</li>
              <li>Category: <a href={`/${category}`}>{category}</a></li>
            </ul>
            <div className="cover">
              <img src={coverUrl || bgtn} alt={category} />
            </div>
            <div className="maintxt">
              {textcontent.map((item, index) => (
                <div key={index}>
                  {item.type === 'p' && <p>{item.text}</p>}
                  {item.type === 'img' && <img src={item.src} alt={item.alt} />}
                  {item.type === 'h1' && <h1>{item.text}</h1>}
                  {item.type === 'h2' && <h2>{item.text}</h2>}
                  {item.type === 'h3' && <h3>{item.text}</h3>}
                  {item.type === 'a' && <a href={item.href} target="_blank" rel="noreferrer">{item.text}</a>}
                </div>
              ))}
            </div>
          </div>
          <div className="adarea">Advertisement</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
