import React from 'react'
import './Card.css'
import { Link } from 'react-router-dom'
import bgtn from "../../Assets/bgtn.png"

const Card = ({ coverUrl=bgtn, page="/Exampleb", date="12 Jul 2025", category="General", title="This is the example of title how it should be"}) => {
  return (
        <div class="box">
        <Link to={page} target="_blank">
          <div class="part_img">
            <img src={coverUrl} alt={category} />
          </div>
            <div class="part_txt">
              <h3>{title}</h3>
              <ul>
                <li>{date}</li>
                <li>|</li>
                <li>{category}</li>
              </ul>
            </div>
          </Link>
        </div>
  )
}

export default Card