import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import './Navbar.css'
import logo from "../../Assets/logo-tp-1b.png"

const Navbar = () => {

    const [menuOpen, setMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };


  return (
    <div>
      <header>
            <div class="container">
                
                <Link to={"/"} class="brand"><img src={logo} alt="img" /><p>writingcell</p></Link>
                {/* <div class="searchbar">
                    <input type="text" placeholder="Search.." /><div class="search_icon"><i class="fa fa-search"></i></div>
                </div> */}
                <div class="button" onClick={toggleMenu}>
                    <span className='s1'></span>
                    <span className='s2'></span>
                    <span className='s3'></span>
                </div>
                <ul id="web">
                    {/* <li><a href="">About us</a></li>
                    <li><a href="">Contact</a></li>
                    <li><a href="donate.html">Donate</a></li> */}
                    {/* <li><a href="/">Home</a></li>
                    <li><a href="#">Category</a></li> */}
                    <li><a href="#">Donate</a></li>
                </ul>
            </div>
            <ul class={`m ${menuOpen ? 'open' : ''}`} id="myLinks">
                    {/* <li><a href="/">Home</a></li>
                    <li><a href="#">Category</a></li> */}
                    <li><a href="#">Donate</a></li>
            </ul>
        </header>
    </div>
  )
}

export default Navbar