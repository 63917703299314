import React from 'react'
import './Footer.css'
// import { useState } from 'react';

const Footer = () => {

  // const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const currentYear = new Date().getFullYear();

  return (
    <div>
      <footer>
        <p>
            Copyright © {currentYear} All rights reserved
        </p>
    </footer>   
    </div>
  )
}

export default Footer