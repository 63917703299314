import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Home/Home';
// import blogData from './BlogData/blog-data.json';
import Blog from './Components/blog/Blog';

const App = () => {
  
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch('https://raw.githubusercontent.com/abhijeets00/writingcell-blog-data/main/blog-data.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchBlogData();
  }, []);

  return (
    <div>
      <Routes>
        {/* {blogData.map((blog, index) => ( */}
        {blogs.map((blog, index) => (
          <Route
            key={index}
            path={`/${blog.title.replace(/\s+/g, '')}`}
            element={<Blog blog={blog} />}
          />
        ))}
        <Route path="/*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
